export default {
    dr: "Doctor",
    doctors: "Doctors",
    add_new: "Add New",

    doctor_list: "Doctor List",
    add_doctor: "Add Doctor",

    doctor_slots: "Doctor Slots",
    doctor: "All Doctor",
    all_dr: "Doctor: All Doctor",
    all_status: "Status : All Status",
    slot_id: "Slot ID ",
    slot_no: "Slot No.",
    date: "Date",
    start_time: "Start Time ",
    end_time: "End Time ",
    status: "Status",
    case_ID: "Case ID",
    comment: "Comment",
    sync_google_calender: "Sync Google Calender",
    showing_1_to_10_of_68_records: "Showing 1 to 10 of 68 records",

    doctor_calender: "Doctor Calender",
    today: "today",
    month: "month",
    week: "week",
    day: "day",
    list: "list",
}