export default {
    staff_list: "Staff List",
    staff_shift: "Staff Shift",
    add_shift: "Add Shift",
    staff: "Staff",
    list: "List",
    today_staff: "Today's staff",
    daily_staff: "Daily staff",
    all_department: "All Department",
    status: "Status",
    all_status: "Status: All Status",
    sync_google_calender: "Sync Google Calender",
    new_staff: "New Staff",
    staff_id: "Staff ID",
    staff_name: "Staff Name",
    phone: "Phone",
    email: "Email",
    department: "Department",
    updated_at: "Updated At",
    updated_by: "Updated By",
    action: "Action",
    records_5: "5 records",
    edit: "Edit",
    delete: "Delete",
    staff_calender: "Staff Calender",
    calender: "Calender",
    assign_staff_to_case: "Assign Staff to Case",
    case_no: "Case No.",
    visit_no: "Visit No.",
    hn: "HN",
    customer: "Customer",
    date: "Date",
    time: "Time",
    you_currently_have_0_items: "You currently have 0 items.",
    showing_1_to_10_of_13_records: "Showing 1 to 10 of 13 records",
    manage: "Manage",
    manage_staff: "Manage Staff",
    assigned_doctor: "Assigned Doctor",
}