import auth from "@/middlewares/auth";

export const productRoutes = [
  {
    path: '/settings/product',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/product/Template'),
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/product/List'),
        name: 'settings_product_list',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'category',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/product/Category'),
        name: 'settings_product_category',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'unit',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/product/Unit'),
        name: 'settings_product_unit',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  }
]