export default {
    patients: "ลูกค้า",
    name: "ชื่อ",
    nickname: "ชื่อเล่น",
    age: "อายุ",
    phone: "โทรศัพท์",
    status: "สถานะ",
    all_status: "สถานะ: ทุกสถานะ",
    action: "เลือกคำสั่ง",
    gen: "เพศ",
    gender: "เพศ: ทุกเพศ",
    male: "ชาย",
    female: "หญิง",
    other: "อื่นๆ",
    active: "คล่องแคล่ว",
    inactive: "ไม่ได้ใช้งาน",
    add_new: "เพิ่ม",
    select_option: "เลือก",
    miss: "นางสาว.",
    mr: "นาย.",
    mrs: "นาง.",
    choose: "เลือก",
    add_emergency_contact: "เพิ่มชื่อผู้ติดต่อฉุกเฉิน",
    view: "เปิด",
    patient_details: "รายละเอียดผู้ป่วย",
    profile: "ประวัติ",
    cases: "เคส",
    documents: "เอกสาร",
    visit_history: "ประวัติ Visit",
    upcoming_appointment: "นัดหมายเร็วๆนี้",
    customer_id: "รหัสลูกค้า",
    hn: "HN",
    hn_number: "หมายเลข HN",
    prefix: "คำนำหน้า",
    first_name: "ชื่อจริง",
    last_name: "นามสกุล",
    nick_name: "ชื่อนิค",
    birthday: "วันเกิด",
    occupation: "อาชีพ",
    nationality: "สัญชาติ",
    national_id: "หมายเลขบัตรประชาชน",
    passport_no: "หมายเลขหนังสือเดินทาง",
    blood_type: "กรุ๊ปเลือด",
    contact: "รายละเอียดติดต่อ",
    country: "ประเทศ",
    backup_phone: "โทรศัพท์สำรอง",
    email: "อีเมล",
    address: "ที่อยู่",
    street: "ถนน",
    street_2: "ถนน 2",
    district: "เขต",
    province: "จังหวัด",
    zip: "รหัสไปรษณีย์",
    phone_home: "โทรศัพท์ (บ้าน)",
    emergency_contact: "ผู้ติดต่อในกรณีฉุกเฉิน",
    relation: "ความสัมพันธ์",
    congenital_disease: "โรคประจำตัว",
    medical_allergies: "ประวัติการแพ้ยา",
    note: "หมายเหตุ",
    delete:"ลบ",
    are_you_sure_want_to_delete:"ยืนยันการลบ?",
    you_wont_be_able_to_revert_this:"คุณจะไม่สามารถเปลี่ยนสิ่งนี้ได้!",
    cancel:"ยกเลิก",
    yes_delete_it:"ใช่ ลบได้เลย",
    deleted:"ถูกลบออกแล้ว",
    patient_has_been_deleted:"ผู้ป่วยถูกลบ!",
    ok:"ตกลง",
}