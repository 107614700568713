export default {
    inventory_list: "Inventory List",
    Inventory: "Inventory",
    list: "List",
    sku: "SKU",
    name_th: "Name (TH)",
    name_en:"Name (EN)",
    remaining:"Remaining",
    safety_stock:"Safety Stock",
    batch:"Batch(es)",
    stock_request:"Stock Request",

    create: "Create",
    view_all: "View All",
    create_request: "Create Request",
    product_id: "Product ID",
    add_new: "Add New",

    manage_consumable:"Manage Consumable",
    all_status: "Status: All Status",
    case_no: "Case No.",
    visit_no: "Visit No.",
    hn: "HN",
    customer: "Customer",
    staff:"Staff",
    date:"Date",
    time:"Time",
    status:"Status",
    action:"Action",
    you_currently_have_0_items: "You currently have 0 items.",
    showing_1_to_10_of_13_records: "Showing 1 to 10 of 13 records",
}