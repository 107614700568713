export default {
  namespaced: true,
  state () {
    return {
      visitObj: null,
    }
  },
  mutations: {
    setVisitObj(state, payload) {
      state.visitObj = payload;
    },
  },
  getters: {
    getVisitObj: (state) => state.visitObj,
  }
}