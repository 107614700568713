export default {
  dashboard: "Dashboard",
  clinic_dashboard : "Clinic Dashboard",
  doctor_today : "Today’s Doctors",
  staff_today : "Today’s Staff",
  days_14__visitation_stat : "14 Days Visitation Stat",
  your_patients_today : "Today’s Visit",
  all_patients : "All patients",
  all_cases : "All cases",
  upcoming_appointments : "Open Tasks",
  service_by_category_this_month : "Service By Category This Month",
  doctor_dashboard : "Doctor's Dashboard",
  no_items : "No item to display",
}