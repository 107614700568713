import auth from "@/middlewares/auth";

export const systemRoutes = [
  {
    path: '/settings/system',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/system/Index.vue'),
    name: 'settings_system',
    meta: {
      middleware: [
        auth
      ]
    },
  }
]