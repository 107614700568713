export default {
    case: "Case",
    request: "Request",
    hn: "HN",
    name: "Name",
    date: "Date",
    time: "Time",
    status: "Status",
    status_all: "Status: All Status",
    showing_1_to_10_of_13_records: "Showing 1 to 10 of 13 records",

    add_new: "Add New",
    add_new_case: "Add New Case",
    visit_no: "Visit No.",
    services_no: "Services No.",
    dr_all_doctor: "Doctor: All Doctor",
    doctor: "Doctor",
    doctor_slot: "Doctor Slot",

    case_details: "Case Details",
    assigned_doctor: "Assigned Doctor",
    other_cases: "Other Cases",
    see_visit: "See Visit",
    vitals: "Vitals",
    body_temperature: "Body Temperature",
    pulse_rate: "Pulse Rate",
    respiration_rate: "Respiration Rate",
    blood_pressure: "Blood Pressure",
    sp_O2: "Sp O2",
    portrait: "Portrait",

    cancel: "Cancel",

    prescription:"Prescription",
    case_no:"Case No.",

    notes:"Notes",
    subjective:"Subjective",
    objective:"Objective",
    assessment:"Assessment",
    plan:"Plan",

    service:"Service",
    service_no:"Service No.",

    staff:"Staff",
    primary:"Primary",
    delete:"Delete",

    consumable:"Consumable",

    follow_up:"Follow - Up",
    
    document:"Document",
    comment:"Comment",
    action:"Action",
    view:"View",
    download:"Download",
}