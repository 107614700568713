import { userRoleRoutes } from "./settings/userRoleRoutes";
import { userRoutes } from "./settings/userRoutes";
import { productRoutes } from "./settings/productRoutes";
import { serviceRoutes } from "./settings/serviceRoutes";
import { systemRoutes } from "./settings/systemRoutes";

let routes = [];

routes.push(...userRoleRoutes);
routes.push(...userRoutes);
routes.push(...productRoutes);
routes.push(...serviceRoutes);
routes.push(...systemRoutes);

export default routes;