export default {
    inventory_list: "รายการสินค้าคงคลัง",
    Inventory: "รายการสินค้า",
    list: "รายการ",
    sku: "SKU",
    name_th: "ชื่อ (th)",
    name_en:"ชื่อ (en)",
    remaining:"จำนวนคงเหลือ",
    safety_stock:"จำนวนขั้นต่ำ",
    batch:"Batch(es)",
    stock_request:"คำขอเบิกสินค้า",

    create: "สร้าง",
    view_all: "ดูทั้งหมด",
    create_request: "ขอเบิกสินค้า",
    product_id: "รหัสผลิตภัณฑ์",
    add_new: "เพิ่ม",

    manage_consumable:"บริหารวัสดุสิ้นเปลือง",
    inventory:"รายการสินค้า",
    all_status: "สถานะ: ทุกสถานะ",
    case_no: "หมายเลขเคส",
    visit_no: "หมายเลข Visit",
    hn: "HN",
    customer: "ลูกค้า",
    staff:"พนักงาน",
    date:"วันที่",
    time:"เวลา",
    status:"สถานะ",
    action:"การกระทำ",
    you_currently_have_0_items: "คุณมี 0 รายการ",
    showing_1_to_10_of_13_records: "แสดงรายการที่ 1 ถึง 10 จาก 13 รายการ",
}