import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import axios from './util/axios'
import VueAxios from 'vue-axios'
import router from "./routes";
import VueSweetalert2 from 'vue-sweetalert2';
import ValidationError from "@/components/ValidationError";
import { SetupCalendar } from 'v-calendar';

import 'sweetalert2/dist/sweetalert2.min.css';
import validationErrors from "@/mixins/validationErrors";
import helperMixins from "@/mixins/helperMixins";
import sidebarMixin from "@/mixins/sidebarMixin";
import { createI18n } from 'vue-i18n'
import enMessages from '@/lang/en/messages'
import thaiMessages from '@/lang/thai/messages'

const messages = {
  en: enMessages,
  thai: thaiMessages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_LOCALE,
  fallbackLocale: 'en',
  messages,
})


window._ = require('lodash')

const app = createApp(App)

app.mixin(validationErrors);
app.use(SetupCalendar, {})
app.mixin(helperMixins);
app.mixin(sidebarMixin);
app.component('v-errors', ValidationError);
app.use(VueAxios, axios)
app.use(i18n)
app.use(store)
app.use(router)

app.use(VueSweetalert2)

app.mount('#app')