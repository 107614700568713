import iframe from "@/middlewares/iframe";
export const iframeRoutes = [
  {
    path: '/iframe/requests',
    component: () => import(/* webpackChunkName: "case" */ '@/views/iframe/request/List.vue'),
    name: 'iframe_requests',
    meta: {
      middleware: [
        iframe
      ]
    }
  },
  {
    path: '/iframe/requests/new',
    component: () => import(/* webpackChunkName: "case" */ '@/views/iframe/request/add/Template.vue'),
    name: 'iframe_requests_new',
    meta: {
      middleware: [
        iframe
      ]
    }
  },
];