import axios from "@/util/axios";
export default {
  namespaced: true,
  state () {
    return {
      doctor: null,
      slot: null,
      services: [],
    }
  },
  mutations: {
    setDoctor(state, payload) {
      state.doctor = payload;
    },
    setSlot(state, payload) {
      state.slot = payload;
    },
    setServices(state, payload) {
      state.services = payload;
    },
  },
  actions: {
    loadServices({commit}) {
      axios.get('/services')
        .then(res => {
          commit('setServices', res.data.data)
        })
    }
  },
  getters: {
    getDoctor: (state) => state.doctor,
    getSlot: (state) => state.slot,
    getServices: (state) => state.services,
  }
}