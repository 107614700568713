import auth from "@/middlewares/auth";

export const userRoleRoutes = [
  {
    path: '/settings/role',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user_type/List'),
    name: 'settings_role',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings/user-type/add',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user_type/Form'),
    name: 'settings_role_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings/role/edit/:id',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user_type/Form'),
    name: 'settings_role_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
]