export default {
  namespaced: true,
  state () {
    return {
      currentLocale: localStorage.getItem('locale') || process.env.VUE_APP_LOCALE,
    }
  },
  mutations: {
    setCurrentLocale(state, payload) {
      state.currentLocale = payload;
    },
  },
  actions: {
    changeLocale: function({commit}, payload) {
      this.$i18n.locale = payload;
      localStorage.setItem('locale', payload);

      commit('setCurrentLocale', payload);
    }
  },
  getters: {
    getCurrentLocale: (state) => state.currentLocale,
  }
}