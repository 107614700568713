<template>
  <nav class="main-nav" role="navigation">

    <!-- Mobile menu toggle button (hamburger/x icon) -->
    <input id="main-menu-state" type="checkbox" />
    <label class="main-menu-btn" for="main-menu-state">
      <span class="main-menu-btn-icon"></span> Toggle main menu visibility
    </label>

    <!-- Sample menu definition -->
    <ul id="main-menu" class="sm sm-blue">
      <li>
        <a href="#">
          <i class="icon-Layout-4-blocks">
            <span class="path1"></span>
            <span class="path2"></span>
          </i> {{ $t("dashboard.dashboard") }}
        </a>
        <ul>
          <li>
            <router-link :to="{name: 'dashboard_clinic'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>
              {{ $t("dashboard.clinic_dashboard") }}
            </router-link>
          </li>
          <li v-if="user.type && user.type === 'doctor'">
            <router-link :to="{name: 'dashboard_doctor'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>
              {{ $t("dashboard.doctor_dashboard") }}
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link :to="{name: 'tasks'}">
          <i class="icon-Compiling"><span class="path1"></span><span class="path2"></span></i>{{ $t('tasks.tasks') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'patients'}">
          <i class="icon-Compiling"><span class="path1"></span><span class="path2"></span></i>{{ $t('patients.patients') }}
        </router-link>
      </li>
      <li><a href="#"><i class="icon-Barcode-read"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.appointments') }}</a>
        <ul>
<!--          <li>
            <router-link :to="{name: 'appointment_request'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Request
            </router-link>
          </li>-->
          <li>
            <router-link :to="{name: 'appointment_calender'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.calender') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'appointment_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.visit_list') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a href="#"><i class="icon-Settings-1"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.case') }}</a>
        <ul>
          <li>
            <router-link :to="{name: 'case'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.case') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'case_requests'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('appointments.request') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li><a href="#"><i class="icon-Diagnostics"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>{{ $t('doctor.doctors') }}</a>
        <ul>
          <li>
            <router-link :to="{name: 'doctor_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('doctor.doctor_list') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'doctor_slots'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('doctor.doctor_slots') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'doctor_calender'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('doctor.doctor_calender') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li><a href="#"><i class="icon-User"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>{{ $t('staff.staff') }}</a>
        <ul>
          <li>
            <router-link :to="{name: 'staff_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('staff.staff_list') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'staff_shift'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('staff.staff_shift') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'staff_calender'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('staff.staff_calender') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'staff_assign'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('staff.assign_staff_to_case') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li><a href="#"><i class="icon-Box"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>{{ $t('inventory.Inventory') }}</a>
        <ul>
          <li>
            <router-link :to="{name: 'inventory_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('inventory.inventory_list') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'inventory_consumable'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('inventory.manage_consumable') }}
            </router-link>
          </li>
        </ul>
      </li>
      <li><a href="#"><i class="icon-Chart-pie"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.reports') }}</a>
        <ul>
          <li><a href="charts_chartjs.html"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.inventory') }}</a></li>
          <li><a href="charts_flot.html"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.visit') }}</a></li>
          <li><a href="charts_inline.html"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.service') }}</a></li>
          <li><a href="charts_morris.html"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.doctor') }}</a></li>
          <li><a href="charts_peity.html"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('reports.staff') }}</a></li>
        </ul>
      </li>
      <li><a href="#"><i class="icon-Settings"><span class="path1"></span><span class="path2"></span></i>{{ $t('setting.settings') }}</a>
        <ul>
          <li>
            <router-link :to="{name: 'settings_product_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('setting.product') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'settings_service_list'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('setting.service') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'settings_user'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>{{ $t('setting.user') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'settings_system'}">
              <i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>System
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  }
}
</script>