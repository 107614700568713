import dashboard from "./dashboard";
import patients from "./patients";
import appointments from "./appointments";
import caseMessage from "./case";
import inventory from "./inventory";
import doctor from "./doctor";
import reports from "./reports";
import setting from "./setting";
import staff from "./staff";
import tasks from "./tasks";

export default {
  dashboard,
  patients,
  appointments,
  case: caseMessage,
  inventory,
  doctor,
  reports,
  setting,
  staff,
  tasks
}