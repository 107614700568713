import guest from "@/middlewares/guest";
import { dashboardRoutes } from "@/routes/routes/dashboardRoutes";
import { patientRoutes } from "@/routes/routes/patientRoutes";
import { appointmentRoutes } from "@/routes/routes/appointmentRoutes";
import { caseRoutes } from "@/routes/routes/caseRoutes";
import { doctorRoutes } from "@/routes/routes/doctorRoutes";
import { staffRoutes } from "@/routes/routes/staffRoutes";
import { inventoryRoutes } from "@/routes/routes/inventoryRoutes";
import { iframeRoutes } from "@/routes/routes/iframeRoutes";
import settingRoutes from "@/routes/routes/settingRoutes";
import { taskRoutes } from "@/routes/routes/taskRoutes";

let routes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]

routes.push(...dashboardRoutes);
routes.push(...patientRoutes);
routes.push(...appointmentRoutes);
routes.push(...caseRoutes);
routes.push(...staffRoutes);
routes.push(...doctorRoutes);
routes.push(...inventoryRoutes);
routes.push(...settingRoutes);
routes.push(...iframeRoutes);
routes.push(...taskRoutes);

export default routes;
