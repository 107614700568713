export default {
    appointments: "Appointments",
    request: "Request",
    request_no: "Request No.",
    patient_name: "Patient Name",
    hn: "HN",
    doctor: "Doctor",
    date: "Date",
    time: "Time",
    services: "Services",
    status: "Status",
    calender: "Calender",
    today: "today",
    month: "month",
    week: "week",
    day: "day",
    visit_list: "Visit List",
    all_doctor : "All Doctor",
    all_status : "All Status",
    visit_no: "Visit No.",
    name: "Name",
    no_of_cases: "No. of Cases",
    action: "Action",
    add_new_visit_list: "Add New Visit List",
    view: "View",
    visit_details: "Visit Details",
    profile: "Profile",
    view_profile: "View Profile",
    h_n: "H/N",
    blood_type: "Blood Type",
    medical_allergies: "Medical Allergies",
    congenital_disease: "Congenital Disease",
    visit_date: "Visit Date",
    visit_time: "Visit Time",
    vitals: "Vitals",
    edit_info: "Edit info",
    edit_vitals_information: "Edit Vitals Information",
    body_temperature: "Body Temperature",
    pulse_rate: "Pulse Rate",
    respiration_rate: "Respiration Rate",
    blood_pressure: "Blood Pressure",
    sp_O2: "Sp O2",
    portrait: "Portrait",
    consent: "Consent",
    add_new: "Add New",
    upload: "Upload",
    select_from_template: "Select From Template",
    record_video: "Record Video",
    cases: "Cases",
    case: "Case",
    room: "Room",
    Prescription: "Prescription",
    case_no: "Case No",
    documents: "Documents",
    comment: "Comment",
    download: "Download",
}