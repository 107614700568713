export default {
  namespaced: true,
  state () {
    return {
      caseObj: null,
    }
  },
  mutations: {
    setCaseObj(state, payload) {
      state.caseObj = payload;
    },
  },
  getters: {
    getCaseObj: (state) => state.caseObj,
  }
}