import auth from "@/middlewares/auth";
export const staffRoutes = [
  {
    path: '/staff/list',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/List'),
    name: 'staff_list',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/staff/shift',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/shift/List.vue'),
    name: 'staff_shift',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/staff/shift/:id',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/shift/edit/Edit.vue'),
    name: 'staff_shift_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/staff/calender',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/Calender'),
    name: 'staff_calender',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/staff/assign',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/assign_case/AssignCase'),
    name: 'staff_assign',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/staff/assign/:id',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/assign_case/Assign'),
    name: 'staff_assign_details',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];