export default {
    dr: "แพทย์",
    doctors: "แพทย์",
    all_dr: "แพทย์: ทุกคน",
    add_new: "เพิ่ม",
    doctor_list: "รายชื่อแพทย์",
    add_doctor: "เพิ่มหมอ",
    doctor_slots: "ตารางแพทย์",
    all_status: "สถานะ: ทุกสถานะ",
    slot_id: "Slot ID",
    slot_no: "หมายเลขตารางแพทย์",
    date: "วันที่",
    start_time: "เวลาเริ่ม",
    end_time: "เวลาสิ้นสุด",
    status: "สถานะ",
    case_ID: "รหัสเคส",
    comment: "หมายเหตุ",
    sync_google_calender: "ซิงค์ปฏิทิน Google",
    showing_1_to_10_of_68_records: "แสดงรายการที่ 1 ถึง 10 จาก 68 รายการ",
    doctor_calender: "ปฏิทินแพทย์",
    today: "วันนี้",
    month: "เดือน",
    week: "สัปดาห์",
    day: "วัน",
    list: "รายการ",
}