import auth from "@/middlewares/auth";
export const dashboardRoutes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Clinic'),
    name: 'dashboard_clinic',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/dashboard/doctor',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Doctor.vue'),
    name: 'dashboard_doctor',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/check-in/:id',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/check_in/Index.vue'),
    name: 'check_in',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];