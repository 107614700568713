import auth from "@/middlewares/auth";
export const patientRoutes = [
  {
    path: '/patients',
    component: () => import(/* webpackChunkName: "patients" */ '@/views/patient/List'),
    name: 'patients',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/patients/:id',
    component: () => import(/* webpackChunkName: "patients" */ '@/views/patient/DetailsTemplate'),
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "patients" */ '@/views/patient/Details'),
        name: 'patients_details',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'cases',
        component: () => import(/* webpackChunkName: "patients" */ '@/views/patient/Cases'),
        name: 'patients_cases',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'documents',
        component: () => import(/* webpackChunkName: "patients" */ '@/views/patient/Documents.vue'),
        name: 'patients_documents',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
    ],
  },
];