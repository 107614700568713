import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user'))|| null,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    checkUser({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/user', {
          headers: {
            'Authorization': `Bearer ${payload}`
          }
        }).then((response) => {
          let user = response.data.data;
          user.token = payload;
          user.type = state.user.type;

          dispatch('setUser', user);

          resolve(true);
        }).catch((error) => {
          dispatch('resetUser');
          reject(error.response.data)
        });
      });
    },
    login({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          let user = response.data.data;
          dispatch('setUser', user);

          resolve(user);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    logout({dispatch}) {
      return new Promise( (resolve) => {
        axios.post('/logout')
          .finally(() => {
            dispatch('resetUser');

            resolve(true);
          });
      });
    },
    setUser({commit}, user) {
      commit('setUser', user);
      localStorage.setItem('user', JSON.stringify(user));

      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    },
    resetUser({commit}) {
      commit('setUser', null);
      localStorage.removeItem('user');
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  getters: {
    getUser: (state) => state.user,
  }
}