import auth from "@/middlewares/auth";
export const caseRoutes = [
  {
    path: '/case',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/List'),
    name: 'case',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/case/requests',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/requests/Index.vue'),
    name: 'case_requests',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/case/requests/:id',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/requests/Details.vue'),
    name: 'case_requests_details',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/case/requests/mapping/:id',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/requests/mapping/Index.vue'),
    name: 'case_requests_mapping',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/case/requests/task-mapping/:id',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/requests/mapping/Index.vue'),
    name: 'case_requests_task_mapping',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/case/:id',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/CaseDetailsTemplate'),
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: 'notes',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Notes'),
        name: 'case_notes',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'nurse-notes',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/NurseNote.vue'),
        name: 'case_nurse_notes',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'documents',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Document'),
        name: 'case_documents',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'follow-up',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/FollowUp.vue'),
        name: 'case_follow_up',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'consumable',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Consumable'),
        name: 'case_consumable',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'staff',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Staff'),
        name: 'case_staff',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'services',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Services'),
        name: 'case_services',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'prescription',
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/Prescription'),
        name: 'case_prescription',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
    ],
  },
];