import auth from "@/middlewares/auth";
export const inventoryRoutes = [
  {
    path: '/inventory/list',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/inventory/List'),
    name: 'inventory_list',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/consumable',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/inventory/consumable/List'),
    name: 'inventory_consumable',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/consumable/:id',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/inventory/consumable/Manage'),
    name: 'inventory_consumable_manage',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/request',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/inventory/NewRequest'),
    name: 'inventory_request',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/request/:id',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/inventory/ViewRequest'),
    name: 'inventory_request_view',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];