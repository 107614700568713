export default {
    appointments:"การนัดหมาย",
    request: "คำขอ",
    request_no: "หมายเลขคำขอ",
    patient_name: "ชื่อลูกค้า",
    hn: "HN",
    doctor: "แพทย์",
    date: "วันที่",
    time: "เวลา",
    services: "บริการ",
    status: "สถานะ",
    all_status: "สถานะ: ทุกสถานะ",

    calender: "ปฏิทิน",
    today: "วันนี้",
    month: "เดือน",
    week: "สัปดาห์",
    day: "วัน",
    visit_list: "รายการ Visit",
    visit_no: "หมายเลข Visit",
    name: "ชื่อ",
    no_of_cases: "จำนวนเคส",
    action: "เลือกคำสั่ง",
    add_new: "เพิ่ม",
    add_new_visit_list: "เพิ่ม Visit ใหม่",
    view: "เปิด",
    visit_details: "รายละเอียด Visit",
    profile: "ประวัติ",
    view_profile: "ดูประวัติ",
    h_n: "H/N",
    blood_type: "กรุ๊ปเลือด",
    medical_allergies: "ประวัติการแพ้ยา",
    congenital_disease: "โรคประจำตัว",
    visit_date: "วันที่ Visit",
    visit_time: "เวลา Visit",
    vitals: "ตรวจร่างกาย",
    edit_info: "แก้ไขข้อมูล",
    edit_vitals_information: "แก้ไขตรวจร่างกาย",
    body_temperature: "อุณหภูมิในร่างกาย",
    pulse_rate: "อัตราชีพจร",
    respiration_rate: "อัตราการหายใจ",
    blood_pressure: "ความดันโลหิต",
    sp_O2: "SP O2",
    portrait: "ภาพถ่าย",
    consent: "การยินยอม",
    upload: "อัพโหลด",
    select_from_template: "เลือกจากเทมเพลต",
    record_video: "บันทึกวิดีโอ",
    cases: "เคส",
    case: "เคส",
    room: "ห้อง",
    prescription: "ใบสั่งยา",
    case_no: "หมายเลขเคส",
    documents: "เอกสาร",
    comment: "หมายเหตุ",
    download: "ดาวน์โหลด",
}