<template>
  <template v-if="$route.name !== 'login'">
    <div class="wrapper" v-if="!$route.path.includes('iframe/')">
      <header-component />
      <nav-bar-component />

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="container-full">
          <!-- Main content -->
          <section class="content">
            <router-view v-slot="{ Component, route }">
              <transition name="fade" mode="out-in">
                <div :key="route.name">
                <component :is="Component" />
                </div>
              </transition>
            </router-view>

          </section>
        </div>
      </div>

      <footer-component />
    </div>

    <router-view v-else />
  </template>

  <Teleport to="body" v-else>
    <router-view></router-view>
  </Teleport>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import NavBarComponent from "@/components/NavBarComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'App',
  components: {FooterComponent, NavBarComponent, HeaderComponent},
  async beforeCreate() {
    this.$i18n.locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE;

    let params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (this.$store.state.auth.user) {
      await this.$store.dispatch('auth/checkUser', this.$store.state.auth.user.token)
        .catch(() => {
          this.logout();
        })
    } else if (params.token) {
      await this.$store.dispatch('auth/checkUser', params.token)
        .catch(() => {
          this.logout();
        })
    }
  },
  methods: {
    logout() {
      // this.$store.dispatch('auth/resetUser');
      this.$router.push({name: 'login'});
    }
  },
  watch: {
    $route() {

      if (this.$route.name === 'login') {
        document.body.classList.add('hold-transition', 'bg-img')
        document.body.style.backgroundImage = "url('/images/bg-1.jpg')";
        document.body.classList.remove('layout-top-nav', 'light-skin', 'fixed')
      } else {
        document.body.style.backgroundImage = "";
        document.body.classList.remove('hold-transition', 'bg-img')
        document.body.classList.add('layout-top-nav', 'light-skin', 'fixed')
      }
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

</style>
