export default {
    case: "เคส",
    request: "ขอ",
    hn: "HN",
    name: "ชื่อ",
    date: "วันที่",
    time: "เวลา",
    status: "สถานะ",
    status_all: "สถานะ: สถานะทั้งหมด",
    showing_1_to_10_of_13_records: "แสดงรายการที่ 1 ถึง 10 จาก ทั้งหมด 13 รายการ",

    add_new: "เพิ่ม",
    add_new_case: "สร้างเคสใหม่",
    visit_no: "หมายเลข Visit",
    services_no: "หมายเลขบริการ",
    dr_all_doctor: "หมอ: หมอทุกคน",
    doctor: "แพทย์",
    doctor_slot: "ตารางแพทย์",

    case_details: "รายละเอียดเคส",
    assigned_doctor: "แพทย์เจ้าของเคส",
    other_cases: "เคสอื่นๆ",
    see_visit: "เปิด Visit",
    vitals: "ประวัติตรวจสุขภาพ",
    body_temperature: "อุณหภูมิร่างกาย",
    pulse_rate: "อัตราชีพจร",
    respiration_rate: "อัตราการหายใจ",
    blood_pressure: "ความดันโลหิต",
    sp_O2: "SP O2",
    portrait: "ภาพถ่าย",

    cancel: "ยกเลิก",

    prescription:"ใบสั่งยา",
    case_no:"หมายเลขเคส",

    notes:"บันทึกแพทย์",
    subjective:"Subjective",
    objective:"Objective",
    assessment:"Assessment",
    plan:"Plan",

    service:"บริการ",
    service_no:"หมายเลขบริการ",

    staff:"พนักงาน",
    primary:"พนักงานหลัก",
    delete:"ลบ",

    consumable:"วัสดุสิ้นเปลือง",

    follow_up:"นัดหมายติดตามอาการ",

    document:"เอกสาร",
    comment:"หมายเหตุ",
    action:"เลือกคำสั่ง",
    view:"เปิด",
    download:"ดาวน์โหลด",
}