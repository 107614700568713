import axios from "@/util/axios";
export default {
  namespaced: true,
  state () {
    return {
      queryParams: {
        search: '',
        product_category_id: '',
        product_group_category_id: '',
        search_columns: 'code,name_sale,productCategory.name_th,productGroupCategory.name_th,balance,productUnit.name_th'
      },
      products: [],
      productCategories: [],
      productGroupCategories: []
    }
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setProductCategories(state, payload) {
      state.productCategories = payload;
    },
    setProductGroupCategories(state, payload) {
      state.productGroupCategories = payload;
    },
    setQueryParams(state, payload) {
      state.queryParams = {...state.queryParams, ...payload};
    },
  },
  actions: {
    loadProducts({commit, state}) {
      axios.get('/inventory/products', {params: state.queryParams})
        .then(res => {
          commit('setProducts', res.data);
        })
    },
    loadProductCategories({commit}) {
      axios.get('/product-categories')
        .then(res => {
          commit('setProductCategories', res.data.data);
        })
    },
    loadProductGroupCategories({commit, state}) {
      commit('setProductGroupCategories', []);

      if (state.queryParams.product_category_id) {
        axios.get('/product-group-categories',
            {
              params: {
                categoryId: state.queryParams.product_category_id
              }
            })
          .then(res => {
            commit('setProductGroupCategories', res.data.data);
          })
      }
    }
  },
  getters: {
    getProducts: (state) => state.products,
    getProductCategories: (state) => state.productCategories,
    getProductGroupCategories: (state) => state.productGroupCategories,
  }
}