export default function auth ({ next, store }){
  let params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (!store.state.auth.user && !params.token) {
    return next({
      name: 'login'
    })
  }

  return next()
}