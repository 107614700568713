export default {
    product: "Product",
    settings: "Settings",
    all_status: "Status: All Status",
    import: "Import",
    upload_file: "Upload File",
    download_sample_file: "Download Sample File",
    showing_1_to_10_of_23_records:"Showing 1 to 10 of 23 records",
    action: "Action",

    view: "View",
    no: "No.",
    lot_no: "Lot No.",

    delete: "Delete",
    are_you_sure_want_to_delete: "Are you Sure want to delete?",
    you_wont_bt_able_to_revert_this: "You won't be able to revert this!",
    cancel:"Cancel",
    yes_delete_it: "Yes, delete it?",
    deleted: "Deleted",
    patient_has_been_deleted: "Patient has been deleted!",
    ok: "OK",
    
    service: "Services",
    records_6: "6 records",

    user: "User",
    user_id: "User ID",
    name:"Name",
    phone:"Phone",
    email:"Email",
    department:"Department",
    updated_at:"Updated At",
    updated_by:"Updated By",
    edit:"Edit",
}