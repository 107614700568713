export default {
  dashboard: "แผงควบคุม",
  clinic_dashboard: "ภาพรวมคลีนิก",
  doctor_today : "แพทย์วันนี้",
  staff_today : "พนักงานวันนี้",
  days_14__visitation_stat: "สถิติ 14 วันย้อนหลัง",
  your_patients_today: "ลูกค้าวันนี้",
  all_patients : "ลูกค้าทั้งหมด",
  all_cases : "ทุกกรณี",
  upcoming_appointments : "นัดหมายเร็วๆนี้",
  service_by_category_this_month : "บริการแต่ละประเภทในเดือนนี้",
  doctor_dashboard : "ภาพรวมของแพทย์",
  no_items : "ไม่มีรายการที่จะแสดง",
}