<template>
  <header class="main-header">
    <div class="inside-header">
      <div class="d-flex align-items-center logo-box justify-content-start">
        <!-- Logo -->
        <router-link :to="{name: 'dashboard_clinic'}" class="logo">
          <!-- logo-->
          <div class="logo-lg">
            <span class="light-logo"><img src="/images/logo.png" alt="logo" style="height: 30px"></span>
            <span class="dark-logo"><img src="/images/logo.png" alt="logo" style="height: 30px"></span>
          </div>
        </router-link>
      </div>
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="app-menu">
          <ul class="header-megamenu nav">
            <li class="btn-group d-lg-inline-flex d-none">
              <div class="app-menu">
                <div class="search-bx mx-5">
                  <form>
                    <div class="input-group">
                      <input type="search" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                      <div class="input-group-append">
                        <button class="btn" type="submit" id="button-addon3"><i class="icon-Search"><span class="path1"></span><span class="path2"></span></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="navbar-custom-menu r-side">
          <ul class="nav navbar-nav">
            <!-- User Account-->
            <li class="dropdown user user-menu" v-if="user">
              <a href="#" class="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent p-0 no-shadow" data-bs-toggle="dropdown" title="User">
                <div class="d-flex pt-1">
                  <div class="text-end me-10">
                    <p class="pt-5 fs-14 mb-0 fw-700 text-primary">{{ user.name }}</p>
                    <small class="fs-10 mb-0 text-uppercase text-mute">{{ user.roles.join(', ') }}</small>
                  </div>
                  <img src="/images/avatar-1.png" class="avatar rounded-10 bg-primary-light h-40 w-40" alt="" />
                </div>
              </a>
              <ul class="dropdown-menu animated flipInX">
                <li class="user-body">
                  <a class="dropdown-item" href="#">
                    <i class="ti-user text-muted me-2"></i> Profile
                  </a>
                  <a class="dropdown-item"
                     @click.prevent="changeLang"
                     href="#">
                    <img class="w-20"
                         :src="'/images/locale/' + ($i18n.locale === 'en' ? 'thai' : 'en') + '.png'"> {{ $i18n.locale === 'en' ? 'Thai' : 'English' }}
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="logout"><i class="ti-lock text-muted me-2"></i> Logout</a>
                </li>
              </ul>
            </li>
            <li class="btn-group nav-item d-lg-inline-flex d-none">
              <a href="#" data-provide="fullscreen" class="waves-effect waves-light nav-link full-screen btn-warning-light" title="Full Screen">
                <i class="icon-Position"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  mounted() {
    window.$('[data-provide~="fullscreen"]').on('click', function () {
      window.screenfull.toggle(window.$('#container')[0]);
    });
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          window.location.href = process.env.VUE_APP_BASE + 'login'
        });
    },
    changeLang() {
      let lang = '';
      if (this.$i18n.locale === 'thai')
        lang = 'en';
      else
        lang = 'thai';

      this.$i18n.locale = lang;
      localStorage.setItem('locale', lang);
    }
  }
}
</script>