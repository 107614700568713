import { createStore } from 'vuex'
import authModule from './modules/auth'
import patientModule from './modules/patientModule'
import caseModule from './modules/caseModule'
import visitModule from './modules/visitModule'
import localeModule from './modules/localeModule'
import iframeModule from './modules/iframeModule'
import prescriptionModule from './modules/prescriptionModule'

const store = createStore({
  modules: {
    auth: authModule,
    patientModule: patientModule,
    caseModule: caseModule,
    visitModule: visitModule,
    localeModule: localeModule,
    iframeModule: iframeModule,
    prescriptionModule: prescriptionModule,
  }
})

export default store