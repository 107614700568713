export default {
    staff_list: "รายชื่อพนักงาน",
    staff_shift: "กะพนักงาน",
    add_shift: "เพิ่มกะ",
    staff: "พนักงาน",
    list: "รายการ",
    today_staff: "พนักงานประจำวันนี้",
    daily_staff: "พนักงานรายวัน",
    all_department: "แผนก: ทุกแผนก",
    status: "สถานะ",
    all_status: "สถานะ: ทุกสถานะ",
    sync_google_calender: "ซิงค์ปฏิทิน Google",
    new_staff: "พนักงานใหม่",
    staff_id: "รหัสพนักงาน",
    staff_name: "ชื่อพนักงาน",
    phone: "โทรศัพท์",
    email: "อีเมล",
    department: "แผนก",
    updated_at: "อัปเดตที่",
    updated_by: "อัปเดตโดย",
    action: "เลือกคำสั่ง",
    records_5: "5 รายการ",
    edit: "แก้ไข",
    delete: "ลบ",

    staff_calender: "ปฏิทินพนักงาน",
    calender: "ปฏิทิน",

    assign_staff_to_case: "จ่ายเคสให้พนักงาน",
    case_no: "หมายเลขเคส",
    visit_no: "หมายเลข Visit",
    hn: "HN",
    customer: "ลูกค้า",
    date: "วันที่",
    time: "เวลา",
    you_currently_have_0_items: "คุณมี 0 รายการ",
    showing_1_to_10_of_13_records: "แสดงรายการที่ 1 ถึง 10 จาก 13 รายการ",

    manage: "จัดการ",
    manage_staff: "บริหารพนักงาน",
    assigned_doctor: "แพทย์เจ้าของเคส",
}