export default {
  methods: {
    initSidebar(s) {
      +function ($) {
        'use strict'

        var DataKey = 'Masteradmin.controlsidebar'

        var Default = {
          slide: true
        }

        var Selector = {
          sidebar: '.' + s,
          data   : '[data-toggle="control-sidebar"]',
          open   : '.control-sidebar-open',
          bg     : '.control-sidebar-bg',
          wrapper: '.wrapper',
          content: '.content-wrapper',
          boxed  : '.layout-boxed'
        }

        var ClassName = {
          open : 'control-sidebar-open',
          fixed: 'fixed'
        }

        var Event = {
          collapsed: 'collapsed.controlsidebar',
          expanded : 'expanded.controlsidebar'
        }

        // ControlSidebar Class Definition
        var ControlSidebar = function (element, options) {
          this.element         = element
          this.options         = options
          this.hasBindedResize = false

          this.init()
        }

        ControlSidebar.prototype.init = function () {
          // Add click listener if the element hasn't been
          // initialized using the data API
          if (!$(this.element).is(Selector.data)) {
            $(this).on('click', this.toggle)
          }

          this.fix()
          $(window).resize(function () {
            this.fix()
          }.bind(this))
        }

        ControlSidebar.prototype.toggle = function (event) {
          if (event) event.preventDefault()

          this.fix()

          if (!$(Selector.sidebar).is(Selector.open) && !$('body').is(Selector.open)) {
            this.expand()
          } else {
            this.collapse()
          }
        }

        ControlSidebar.prototype.expand = function () {
          if (!this.options.slide) {
            $('body').addClass(ClassName.open)
          } else {
            $(Selector.sidebar).addClass(ClassName.open)
          }

          $(this.element).trigger($.Event(Event.expanded))
        }

        ControlSidebar.prototype.collapse = function () {
          $('body, ' + Selector.sidebar).removeClass(ClassName.open)
          $(this.element).trigger($.Event(Event.collapsed))
        }

        ControlSidebar.prototype.fix = function () {
          if ($('body').is(Selector.boxed)) {
            this._fixForBoxed($(Selector.bg))
          }
        }

        // Private

        ControlSidebar.prototype._fixForBoxed = function (bg) {
          bg.css({
            position: 'absolute',
            height  : $(Selector.wrapper).height()
          })
        }

        // Plugin Definition
        function Plugin(option) {
          return this.each(function () {
            var $this = $(this)
            var data  = $this.data(DataKey)

            if (!data) {
              var options = $.extend({}, Default, $this.data(), typeof option === 'object' && option)
              $this.data(DataKey, (data = new ControlSidebar($this, options)))
            }

            if (typeof option == 'string') data.toggle()
          })
        }

        var old = $.fn.controlSidebar

        $.fn.controlSidebar             = Plugin
        $.fn.controlSidebar.Constructor = ControlSidebar

        // No Conflict Mode
        $.fn.controlSidebar.noConflict = function () {
          $.fn.controlSidebar = old
          return this
        }

        // ControlSidebar Data API
        $(document).on('click', Selector.data, function (event) {
          if (event) event.preventDefault()
          Plugin.call($(this), 'toggle')
        })

      }(window.jQuery) // End of use strict
    }
  }
}