import auth from "@/middlewares/auth";
export const appointmentRoutes = [
  {
    path: '/appointment/calender',
    component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/Calender'),
    name: 'appointment_calender',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/appointment/list',
    component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/List'),
    name: 'appointment_list',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/visit/:id',
    component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/VisitDetails/VisitDetailsTemplate'),
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: 'cases',
        component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/VisitDetails/VisitDetailsCases'),
        name: 'visit_details_cases',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'documents',
        component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/VisitDetails/VisitDetailsDocuments'),
        name: 'visit_details_documents',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        path: 'prescriptions',
        component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/VisitDetails/VisitDetailsPrescription'),
        name: 'visit_details_prescriptions',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        },
      },
    ],
  },
  {
    path: '/appointment/request',
    component: () => import(/* webpackChunkName: "appointment" */ '@/views/appointment/Request'),
    name: 'appointment_request',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];