import auth from "@/middlewares/auth";

export const userRoutes = [
  {
    path: '/settings/user',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user/List'),
    name: 'settings_user',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings/user/add',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user/Form'),
    name: 'settings_user_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings/user/edit/:id',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/user/Form'),
    name: 'settings_user_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
]