import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      patient: null,
    }
  },
  mutations: {
    setPatient(state, payload) {
      state.patient = payload;
    },
  },
  actions: {
    loadPatient({commit}, payload) {
      commit('setPatient', null);

      return new Promise((resolve, reject) => {
        axios.get('/patients/' + payload).then((response) => {
          commit('setPatient', response.data.data);

          resolve(response.data.data);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    }
  },
  getters: {
    getPatient: (state) => state.patient,
  }
}