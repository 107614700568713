import auth from "@/middlewares/auth";
export const doctorRoutes = [
  {
    path: '/doctor/list',
    component: () => import(/* webpackChunkName: "doctor" */ '@/views/doctor/List'),
    name: 'doctor_list',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/doctor/slots',
    component: () => import(/* webpackChunkName: "doctor" */ '@/views/doctor/Slots'),
    name: 'doctor_slots',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/doctor/calender',
    component: () => import(/* webpackChunkName: "doctor" */ '@/views/doctor/Calender'),
    name: 'doctor_calender',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];