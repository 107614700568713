export default {
    product: "ผลิตภัณฑ์",
    settings: "การตั้งค่า",
    all_status: "สถานะ: ทุกสถานะ",
    import: "นำเข้า",
    upload_file: "อัปโหลดไฟล์",
    download_sample_file: "ดาวน์โหลดไฟล์ตัวอย่าง",
    showing_1_to_10_of_23_records:"แสดงรายการที่ 1 ถึง 10 จาก 23 รายการ",
    action: "เลือกคำสั่ง",

    view: "เปิด",
    no: "หมายเลข",
    lot_no: "หมายเลขล็อต",

    delete: "ลบ",
    are_you_sure_want_to_delete: "ยืนยีนว่าต้องการลบ?",
    you_wont_bt_able_to_revert_this: "คุณจะไม่สามารถเปลี่ยนสิ่งนี้ได้!",
    cancel:"ยกเลิก",
    yes_delete_it: "ใช่ ลบได้เลย",
    deleted: "ถูกลบแล้ว",
    patient_has_been_deleted: "ผู้ป่วยถูกลบ!",
    ok: "ตกลง",
    
    service: "บริการ",
    records_6: "6 รายการ",

    user: "ผู้ใช้",
    user_id: "รหัสผู้ใช้",
    name:"ชื่อ",
    phone:"โทรศัพท์",
    email:"อีเมล",
    department:"แผนก",
    updated_at:"อัปเดตที่",
    updated_by:"อัปเดตโดย",
    edit:"แก้ไข",
}