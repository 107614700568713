import auth from "@/middlewares/auth";

export const serviceRoutes = [
  {
    path: '/settings/service',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Template'),
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/List'),
        name: 'settings_service_list',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'add',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Form'),
        name: 'settings_service_add',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Form'),
        name: 'settings_service_edit',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'station',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Station'),
        name: 'settings_service_station',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'unit',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Unit'),
        name: 'settings_service_unit',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'category',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/setting/service/Category'),
        name: 'settings_service_category',
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
    ]
  }
]