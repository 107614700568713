export default {
    patients: "Patients",
    name: "Name",
    nickname: "Nickname",
    age: "Age",
    gen: "Gender",
    gender: "Gender : All Gender",
    phone: "Phone",
    status: "Status",
    action: "Action",
    male: "Male",
    female: "Female",
    all_status : "Status: All Status",
    active: "Active",
    inactive: "Inactive",
    add_new: "ADD NEW",
    select_option: "Select Option",
    miss: "Miss.",
    mr: "Mr.",
    mrs: "Mrs.",
    choose: "Choose",
    add_emergency_contact: "ADD Emergency Contact",
    view: "View",
    patient_details: "Patient Details",
    profile: "Profile",
    cases: "Cases",
    documents: "Documents",
    visit_history: "Visit History",
    upcoming_appointment: "Upcoming Appointment",
    customer_id: "Customer ID",
    hn: "HN",
    hn_number: "HN Number",
    prefix: "Prefix",
    first_name: "First Name",
    last_name: "Last Name",
    nick_name: "Nick Name",
    birthday: "Birthday",
    occupation: "Occupation",
    nationality: "Nationality",
    national_id: "National ID",
    passport_no: "Passport No.",
    blood_type: "Blood Type",
    contact: "Contact",
    country: "Country",
    backup_phone: "Backup Phone",
    email: "Email",
    address: "Address",
    street: "Street",
    street_2: "Street 2",
    district: "District",
    province: "Province",
    zip: "Zip",
    phone_home: "Phone (Home)",
    emergency_contact: "Emergency Contact",
    relation: "Relation",
    congenital_disease: "Congenital Disease",
    medical_allergies: "Medical Allergies",
    note: "Note",
    delete:"Delete",
    are_you_sure_want_to_delete:"Are you sure want to delete?",
    you_wont_be_able_to_revert_this:"You won't be able to revert this!",
    cancel:"Cancel",
    yes_delete_it:"Yes, delete it?",
    deleted:"Deleted",
    patient_has_been_deleted:"Patient has been deleted!",
    ok:"OK",
}