import auth from "@/middlewares/auth";
export const taskRoutes = [
  {
    path: '/tasks',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/List.vue'),
    name: 'tasks',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/tasks/:id',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/Details.vue'),
    name: 'tasks_view',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];